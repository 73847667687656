var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_modules_title_return bbox d-flex align-center justify-space-between",
    },
    [
      _c("div", { staticClass: "left d-flex align-center" }, [
        _c("img", {
          staticClass: "icon mr-20 cp",
          attrs: {
            src: require("@/assets/images/shopping_mail/search_back.png"),
            alt: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("handleReturn")
            },
          },
        }),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("search-module", {
            attrs: { props_keyword: _vm.keyword },
            on: { handleChange: _vm.handleChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }