<template>
  <div
    class="views_shopping_mail_modules_title_return bbox d-flex align-center justify-space-between"
  >
    <div class="left d-flex align-center">
      <img
        src="~@/assets/images/shopping_mail/search_back.png"
        alt=""
        class="icon mr-20 cp"
        @click.stop="$emit('handleReturn')"
      >
      <div class="title">{{title}}</div>
    </div>
    <div class="right">
      <search-module
        @handleChange="handleChange"
        :props_keyword="keyword"
      ></search-module>
    </div>
  </div>
</template>

<script>
import SearchModule from '../Common/Search.vue';
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    keyword: {
      type: String,
      default: '',
    },
  },
  components: {
    SearchModule,
  },
  methods: {
    handleChange(data){
      this.$emit('handleChange', data);
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_title_return{
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
  border-bottom: 1px solid #f4f4f4;
}
</style>
