<!--
 * @Author       : Hugo
 * @Date         : 2022-01-07 16:16:59
 * @LastEditTime : 2022-01-14 11:22:34
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/ShoppingMail/Pages/Order/List.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="views_shopping_mail_pages_order_list bbox"
  >
    <div class="page_wrapper d-flex align-start flex-column">
      <div class="sticky_group">
        <return-title
          class="padding_x py-20 bbox"
          title="我的订单"
          :keyword="keyword"
          @handleReturn="handleReturn"
          @handleChange="handleChangeKeyword"
        ></return-title>
        <div class="list_header_group padding_x py-20 bbox">
          <div class="list_header d-flex align-center ">
              <div class="unit details">订单详情</div>
              <div class="unit price">售价</div>
              <div class="unit account_number">账户数</div>
              <div class="unit bind_vip">绑定VIP</div>
              <div class="unit actual_payment">实付款</div>
              <div class="unit operation">操作</div>
          </div>
        </div>
      </div>
      <div class="main_group padding_x py-20 bbox">
        <template
          v-for="(item, index) in order_list"
        >
          <unit-module
            :key="index"
            :item="item"
            class="mb-20"
            @handleView="handleView"
          ></unit-module>
        </template>
        <div class="loadmore_group d-flex align-center justify-center"
          v-if="!nomore_order"
        >
          <div class="text un_sel cp"
            v-if="!loading_order"
            @click.stop="loadmore();"
          >加载更多</div>
          <loading-icon
            v-else
            size="20px"
          ></loading-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnTitle from '@/views/ShoppingMail/Modules/Title/Return.vue';
import UnitModule from './Unit.vue';
import {POrder} from '@/types';
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  components: {
    ReturnTitle,
    UnitModule,
    LoadingIcon,
  },
  data(){
    return{
      order_list: [],
      keyword: '',
      nomore_order: false,
      loading_order: false,
    }
  },
  mounted(){
    this.init();
    this.initNavigation();
  },
  methods: {
    async init(){
      this.clearOrder();
      this.$nextTick(function(){
        this.getList();
      })
    },
    initNavigation(){
      const {$route, $store} = this;
      const {state, commit} = $store;
      const {spm_nav} = state;
      // 如果导航存在 且一级不是我的订单 则备份
      if(spm_nav && (spm_nav[0].type !== 'route' || (spm_nav[0].route_data && spm_nav[0].route_data.navigation_title !== '我的订单'))){
        this.$api.shopping_mail.backupNavigation();
      }

      // 导航不存在 或 一级不是我的订单 重新设置
      if(!spm_nav || (spm_nav[0].type !== 'route' || (spm_nav[0].route_data && spm_nav[0].route_data.navigation_title !== '我的订单'))){
        commit('set_spm_nav', [
          ...new Set([
            ...[
              {
                type: 'route',
                route: $route,
                route_data: {navigation_title: '我的订单'},
              }
            ]
          ])
        ])
      }

      // console.log(state.spm_nav);
    },
    clearOrder(){
      this.$set(this, 'order_list' , []);
      this.nomore_order = false;
      this.loading_order = false;
    },
    async getList(){
      const {loading_order, nomore_order, keyword, order_list,} = this;
      if(loading_order || nomore_order){
        return false;
      }
      this.loading_order = true;
      const data = {
        keyword,
        start: order_list.length || '',
      }
      const res = await this.$global.doPost({
        url: '/order/myOrder',
        data,
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {orderlist} = contents;
        if(!orderlist || orderlist.length< 20 ){
          this.nomore_order = true;
        }
        const list = this.$types.transformObjectForList({
          list: orderlist,
          obj: POrder,
        })
        this.$set(this, 'order_list', [
          ...new Set([
            ...this.order_list,
            ...list,
          ])
        ])
      }
      this.loading_order = false;
    },
    handleReturn(){
      const {state, commit} = this.$store;
      const {prev_route} = state;
      if(prev_route === undefined){ // 没有上一级 返回商城首页
        this.$router.push({name: 'shopping_mail'});
      }
      if(prev_route !== undefined){
        const {name, params} = prev_route;
        this.$router.push({name, params});
        if(this.$store.state.spm_prev_nav){
          this.$api.shopping_mail.recoverNavigation();
        }
        commit('set_prev_route', undefined); // 跳转之后置空
      }
      
    },
    handleChangeKeyword(data){
      const {keyword, do_search} = data;
      if(do_search){
        this.keyword = keyword;
        this.init();
      }
    },
    async loadmore(){
      await this.getList();
    },
    /*
    * 教学包预览任务
    */
    handleView(item){
      // this.$emit('handlePushNavigation', {type: 'task', task_data: item, payment: true,})
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true});
    },
  }
  
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_pages_order_list{
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px 0 0;
  position: relative;
  overflow: auto;
  // 最小宽度 1200 -310
  min-width: 890px;
  .page_wrapper{
    @include scrollbar;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .sticky_group{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    width: 100%;
  }
  .padding_x{
    padding: {
      left: 40px;
      right: 40px;
    }
  }
  .main_group{
    flex: 1;
    width: 100%;
    position: relative;
    z-index: 2;
    // background-color: #f4f4f4;
  }
  .white{
    background-color: #fff;
  }
  .list_header_group{
    width: 100%;
  }
  .list_header{
    width: 100%;
    font-size: 14px;
    color: #999;
    height: 40px;
    background-color: #f4f4f4;
    .unit{
      flex: 1;
      flex-shrink: 0;
      box-sizing: border-box;
      padding: 0 10px;
    }
  }
  .unit.details{
    min-width: 400px;
  }
  .unit.operation{
    min-width: 100px;
  }
  .loadmore_group{
    padding: 10px 0;
    .text{
      line-height: 20px;
      font-size: 14px;
      color: $main_blue;
    }
  }
}
</style>

