var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c("div", { staticClass: "views_shopping_mail_pages_order_unit bbox" }, [
        _c("div", { staticClass: "info_group d-flex align-center" }, [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              "成交时间：" +
                _vm._s(
                  _vm.$moment(_vm.item.timeEnd).format("YYYY-MM-DD hh:mm:ss")
                )
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "order_number",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doCopy(_vm.item.orderNum)
                },
              },
            },
            [_vm._v("订单编号：" + _vm._s(_vm.item.orderNum))]
          ),
        ]),
        _c("div", { staticClass: "details_group d-flex align-stretch" }, [
          _c("div", { staticClass: "unit details d-flex align-start" }, [
            _vm.show_type
              ? _c("img", {
                  staticClass: "icon mr-10",
                  attrs: {
                    src: require(`@/assets/images/shopping_mail/order/icon/${_vm.type}.png`),
                    alt: "",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "name cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleView()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(`${_vm.type_hint}：`) + _vm._s(_vm.item.caseSubject)
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "unit price" }, [
            _vm._v(
              _vm._s(
                _vm.toFixed2(_vm.item.singleFee) !== false
                  ? `¥ ${_vm.toFixed2(_vm.item.singleFee)}`
                  : "—"
              )
            ),
          ]),
          _c("div", { staticClass: "unit account_number" }, [
            _vm._v(_vm._s(_vm.item.sellnum || "—")),
          ]),
          _c("div", { staticClass: "unit bind_vip" }, [
            _vm._v(
              _vm._s(_vm.item.vipyears ? `VIP×${_vm.item.vipyears}年` : "—")
            ),
          ]),
          _c("div", { staticClass: "unit actual_payment" }, [
            _vm._v(
              _vm._s(
                _vm.toFixed2(_vm.item.totalFee) !== false
                  ? `¥ ${_vm.toFixed2(_vm.item.totalFee)}`
                  : "—"
              )
            ),
          ]),
          _c(
            "div",
            { staticClass: "unit operation d-flex flex-column align-start" },
            [
              _vm.show_code
                ? _c(
                    "div",
                    {
                      staticClass: "button cp",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.viewCode()
                        },
                      },
                    },
                    [_vm._v("查看激活码")]
                  )
                : _vm._e(),
              0
                ? _c("div", { staticClass: "button" }, [_vm._v("查看模板列表")])
                : _vm._e(),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }