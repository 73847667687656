<!--
 * @Author       : Hugo
 * @Date         : 2022-01-07 16:42:09
 * @LastEditTime : 2022-01-12 10:47:23
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/ShoppingMail/Pages/Order/Unit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="views_shopping_mail_pages_order_unit bbox"
    v-if="item"
  >
    <div class="info_group d-flex align-center">
      <div class="time">成交时间：{{$moment(item.timeEnd).format('YYYY-MM-DD hh:mm:ss')}}</div>
      <div class="order_number"
        @click.stop="doCopy(item.orderNum)"
      >订单编号：{{item.orderNum}}</div>
    </div>
    <div class="details_group d-flex align-stretch">
      <div class="unit details d-flex align-start"
        
      >
        <img 
          v-if="show_type"
          :src="require(`@/assets/images/shopping_mail/order/icon/${type}.png`)" alt="" class="icon mr-10">
        <div class="name cp"
          @click.stop="handleView();"
        >{{`${type_hint}：`}}{{item.caseSubject}}</div>
      </div>
      <div class="unit price">{{(toFixed2(item.singleFee) !== false) ? `¥ ${toFixed2(item.singleFee)}` : '—'}}</div>
      <div class="unit account_number">{{item.sellnum || '—'}}</div>
      <div class="unit bind_vip">{{item.vipyears ? `VIP×${item.vipyears}年` : '—'}}</div>
      <div class="unit actual_payment">{{(toFixed2(item.totalFee) !== false) ? `¥ ${toFixed2(item.totalFee)}` : '—'}}</div>
      <div class="unit operation d-flex flex-column align-start">
        <div class="button cp"
          v-if="show_code"
          @click.stop="viewCode();"
        >查看激活码</div>
        <div class="button"
          v-if="0"
        >查看模板列表</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: undefined,
    }
  },
  data(){
    return {
    }
  },
  computed: {
    type(){
      const {item} = this;
      const {caseType} = item; // 商品类型(1.会员充值)(2.模板购买)(3.定制购买)
      let type = 'task';
      switch(caseType){
        case(1):
          type = 'member';
          break;
        case(2):
          type = 'task';
          break;
        case(3):
          type = 'custom';
          break;
        default: 
          type = '';
          break;
      }

      return type;
    },
    type_hint(){
      const {type} = this;
      let type_hint = '';
      switch(type){
        case('member'): 
          type_hint = '会员';
          break;
        case('task'):
          type_hint = '模板';
          break;
        case('custom'):
          type_hint = '定制';
          break;
        default:
          type_hint = '';
          break;
      }
      return type_hint;

    },
    show_type(){
      const {type} = this;
      switch(type){
        case('member'):
          return false;
        case(''):
          return false;
        default:
          return true;
      }
    },
    show_code(){
      const {item} = this;
      const {selforother} = item;
      if(selforother === 1){
        return true;
      }
      return false;
    },

  },
  methods: {
    toFixed2(num){
      if((num??'') === ''){
        return false;
      }
      return Number(num/100).toFixed(2);
    },
    viewCode(){
      this.$view_keys({title: '查看激活码', order: this.item});
    },
    doCopy(text){
      this.$clipboard.copyText({text, notice: '订单号已复制'});
    },
    handleView(){
      this.$emit('handleView', this.item);
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_pages_order_unit{
  width: 100%;
  font-size: 14px;
  color: #333;
  border: 2px solid #E7EAED;
  .unit{
    flex: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 30px 10px;
    border-right: 1px solid #E7EAED;
    &:last-child{
      border-right: none;
    }
  }
  .unit.details{
    min-width: 400px;
    padding-left: 8px;
  }
  .unit.operation{
    min-width: 100px;
  }
  .info_group{
 
    width: 100%;
    height: 40px;
    background: #E5EEF6;
    color: #666;
    .time{
      margin-right: 60px;
      margin-left: 8px;
    }
   
  }
  .details{
    .icon{ 
      display: block;
      width: 24px;
      height: 24px;
    }
    .name{
      @include transition;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      word-break: break-all;
      text-align: justify;
      text-justify: inter-ideograph;
      text-align-last: left;
      // line-height: 21px;
      // border-bottom: 1px solid transparent;
      &:hover{
        text-decoration: underline;
        // border-bottom: 1px solid #333;
      }
    }
  }
  .operation{
    .button{
      @include transition;
      color: $main_blue;
      margin-bottom: 3px;
      &:last-child{
        margin-bottom: 0;
      }
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
</style>